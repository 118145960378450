<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Logs do Usuário</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="12" :sm="16">
              <el-row type="flex" justify="end">
                <el-select
                  remote
                  value-key="uid"
                  :loading="!users?.length"
                  @visible-change="fetchUsers"
                  @change="selectUser"
                  v-model="filterItemsBy"
                  no-data-text="Não existem logs para este usuário"
                  size="medium"
                >
                  <template #prefix>Filtrar por:</template>
                  <el-option
                    v-for="item in users"
                    :key="item.uid"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="UserLogs"
      v-if="filterItemsBy"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="entity" label="entidade"> </el-table-column>
      <el-table-column prop="details" label="descrição"> </el-table-column>
      <el-table-column prop="ip" label="ip"> </el-table-column>
      <el-table-column prop="created_at" label="efetuado em"> </el-table-column>
    </el-table>
    <el-row justify="center" v-else>
      <el-col :md="24">
        <h5>Selecione um usuário para visualizar os logs</h5>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { maska } from "maska";
import { ElNotification } from "element-plus";
export default {
  name: "UserGroups",
  data: () => ({
    hasError: false,
    isLoading: false,
    filterItemsBy: null,
    createMask: maska,
    users: [],
    user: null,
  }),
  methods: {
    fetchUsers(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}users`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.users = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
    },
    fetchUserLogs() {
      if (this.user)
        fetch(`${this.$store.state.apiUrl}users/${this.user.uid}`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.json();
          })
          .then(
            (json) => (this.user = json),
            (e) =>
              e.then((e) =>
                ElNotification.error({
                  title: "Não foi possível obter os logs",
                  message: e.message,
                })
              )
          )

          .finally(() => (this.isLoading = false));
    },
    selectUser(v) {
      this.isLoading = true;
      this.user = v;
      this.fetchUserLogs();
    },
  },
  computed: {
    UserLogs() {
      return this.user?.logs || [];
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>